var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.competence)?_c('div',[_c('div',{staticClass:"mb-4"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_vm._v("Все компетенции")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","fab":"","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}],null,false,1849359523)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.settingsDialog = true}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-tools")])],1),_c('v-list-item-title',[_vm._v("Настройки компетенции")])],1),_c('v-list-item',{on:{"click":function($event){_vm.compLevelDialog = true}}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-tune-variant")])],1),_c('v-list-item-title',[_vm._v("Пороговые баллы")])],1),_c('v-list-item',{attrs:{"to":{
                name: 'competence_scores',
              }}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-chart-bar")])],1),_c('v-list-item-title',[_vm._v("Баллы участников")])],1),_c('v-list-item',{attrs:{"to":{
                name: 'competence_sync',
              }}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-sync")])],1),_c('v-list-item-title',[_vm._v("Пересчет баллов")])],1),_c('v-list-item',{attrs:{"to":{
                name: 'competence_achievements_sync',
              }}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-certificate")])],1),_c('v-list-item-title',[_vm._v("Выдача достижений")])],1)],1)],1),_c('h1',{staticClass:"flex-grow-1"},[_vm._v(" Компетенция «"+_vm._s(_vm.competence.name)+"» ")])],1)],1),(!_vm.pending && _vm.competenceWarnings.length)?_c('v-alert',{staticClass:"my-4",attrs:{"border":"left","colored-border":"","type":"warning","elevation":"2"}},[_c('p',[_c('strong',[_vm._v("Параметры компетенции!")])]),_c('ul',_vm._l((_vm.competenceWarnings),function(item,idx){return _c('li',{key:idx},[_vm._v(" "+_vm._s(item)+" ")])}),0)]):_vm._e(),(!_vm.pending)?_c('router-view',{attrs:{"competence":_vm.competence,"current-tracks":_vm.currentTracks,"thresholds":_vm.thresholds},on:{"onUpdateTracks":_vm.getCompTracks,"openTresholds":function($event){_vm.compLevelDialog = true},"onOpenAssignDialog":function($event){_vm.assignDialog = true}}}):_vm._e()],1):_vm._e(),_c('assign-track-dialog',{attrs:{"competence":_vm.competence,"selected-tracks":_vm.compTracks},on:{"onSubmit":_vm.getCompTracks},model:{value:(_vm.assignDialog),callback:function ($$v) {_vm.assignDialog=$$v},expression:"assignDialog"}}),_c('competence-create-dialog',{attrs:{"competence":_vm.competence},model:{value:(_vm.settingsDialog),callback:function ($$v) {_vm.settingsDialog=$$v},expression:"settingsDialog"}}),_c('competence-threshold',{attrs:{"competence":_vm.competence,"thresholds":_vm.thresholds},on:{"onUpdate":_vm.getThresholds,"onRemoveSetting":_vm.removeSetting},model:{value:(_vm.compLevelDialog),callback:function ($$v) {_vm.compLevelDialog=$$v},expression:"compLevelDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }